import React from 'react';

const SmallCopy = ({title = '', titleBig = false, children}) => {
  return (
    <div className='max-w-7xl mx-auto px-4 md:px-10'>
      <div className='max-w-7xl py-8 md:py-16 md:grid md:grid-cols-12 md:gap-10'>
        <div className="md:col-span-3 mb-8 md:mb-0">
          <h1 className={`${titleBig ? 'text-black text-2xl' : 'text-neutral-300 text-xl'} font-bold  leading-tight`}>
            {title}
          </h1>
        </div>
        <div className='md:col-start-7 md:col-span-6 mb-8 md:mb-0 text-justify'>
          {children}
        </div>
      </div>
      <hr className="my-4 border-t border-neutral-200" />
    </div>
  );
};

export default SmallCopy;