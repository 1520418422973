import React from 'react';
import HeroImage from '../assets/hero.png'

const Hero = () => {
  return (
    <div className='max-w-7xl mx-auto px-4 md:px-10'>
      <div className='max-w-7xl py-8 md:py-16 md:grid md:grid-cols-12 md:items-center md:gap-10'>
        <div className="md:col-span-4 mb-8 md:mb-0">
          <h1 className="text-2xl font-bold text-black leading-tight">
            The unified platform for smart vending and interactive kiosks.
          </h1>
        </div>
        <div className='md:col-start-7 md:col-span-6 mb-8 md:mb-0'>
          <img src={HeroImage} alt="Interactive Kiosk" className="w-full h-auto" />
        </div>
      </div>
      <hr className="my-4 border-t border-neutral-200" />
    </div>
  );
};

export default Hero;