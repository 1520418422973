// Import necessary React Router components
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// Import your components
import Navbar from './components/NavBar';
import Footer from './components/Footer';
import Hero from './components/Hero';
import SmallCopy from './components/SmallCopy';
import Explore from './components/Explore';
import AboutImage from './assets/about.png'

const Home = () => (
  <div>
    <Hero/>
    <SmallCopy title='What is vendOS?'>
      <p>vendOS is an all-in-one solution to deploy and manage your fleet of smart vending machines and kiosks.</p>
      <br/>
      <p>We have built a seamless integration of hardware, software, and cloud services to create an unparalleled user and operator experience.</p>
    </SmallCopy>
    <Explore />
  </div>
);

const About = () => (
  <div>
    <div className='max-w-7xl mx-auto px-4 py-16 md:px-10'>
      <img src={AboutImage} alt='About header' />
    </div>
    <SmallCopy title='Where we came from & where we are going.' titleBig={true}>
      <p>Our journey in the smart vending industry began nearly a decade ago with an ambitious goal: to develop a vending machine that rewards customer engagement with free products. This idea propelled us from our modest start to becoming industry innovators, working alongside a vast number of the FTSE 100 on numerous impactful campaigns.</p>
      <br/>
      <p>vendOS has been our passion project from the start, evolving behind the scenes as we sought to revolutionize the vending experience. Now, we're taking a bold step forward by releasing vendOS to the world, aiming to partner with manufacturers to pre-install our software on vending machines as a standard feature. This launch is accompanied by the development of additional tools and features, designed to support larger scale, long-term deployments.</p>
      <br />
      <p>Our ambition is to set a new standard in smart vending, making innovative, interactive vending experiences accessible to a wider audience. Join us as we embark on this exciting phase of our journey, shaping the future of automated retail together.</p>
    </SmallCopy>
  </div>
);

export default function App() {
  return (
    <Router>
      <div>
        <Navbar />
        <Routes>
          <Route path="/about" element={<About />} />
          <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}