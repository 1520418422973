import React from 'react';
import HardwareImage from '../assets/hardware.png'
import AppsImage from '../assets/apps.png'
import CloudImage from '../assets/cloud.png'
import ControlPanelImage from '../assets/controlPanel.png'

const Element = ({ title = '', image = HardwareImage, children }) => {
  return (
    <div className='py-10 md:py-20 md:grid md:grid-cols-12 md:gap-10'>
      <div className="md:col-span-2 mb-8 md:mb-0">
        <h1 className="text-xl font-bold text-neutral-300 leading-tight">
          {title}
        </h1>
      </div>
      <div className='md:col-start-3 md:col-span-4 mb-8 md:mb-0 text-justify text-base'>
        {children}
      </div>
      <div className='md:col-start-8 md:col-span-5 mb-8 md:mb-0'>
        <img src={image} alt="Interactive Kiosk" className="w-full h-auto" />
      </div>
    </div>
  )
}



const Explore = () => {
  return (
    <div className="max-w-7xl mx-auto px-4 md:px-10">
      <Element title='Hardware' image={HardwareImage}>
        <p>We offer a selection of custom-built vending machines and interactive kiosks, designed to integrate seamlessly with the vendOS platform.</p>
      </Element>
      <Element title='Apps' image={AppsImage}>
        <p>At the core of vendOS is the principle of limitless customization, enabling a unique customer experience. From captivating games and dynamic digital shopfronts to streamlined restaurant ordering stations, vendOS provides unmatched flexibility.</p>
      </Element>
      <Element title='Cloud' image={CloudImage}>
        <p>Our innovative cloud platform redefines fleet management by offering unprecedented visibility and customization. Experience a management dashboard unlike any other.</p>
        <br/>
        <p>With vendOS, you're not just getting an upgrade; you're stepping into the future of vending and kiosk technology.</p>
      </Element>
      <Element title='Control Panel' image={ControlPanelImage}>
        <p>The Control Panel marks a breakthrough in on-device management. This password-protected portal enables swift and efficient access to device logs, configuration of connected hardware, and rapid response to hardware exceptions in the field.</p>
        <br/>
        <p>Designed for ease and efficiency, it empowers operators to maintain optimal performance seamlessly.</p>
      </Element>
    </div>
  );
};

export default Explore;