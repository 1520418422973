import React from 'react';
// import TerminalGroup from '../assets/TerminalGroup.svg';

const Footer = () => {

  return (
    <nav className="bg-white border-neutral-200 border-t">
      <div className='max-w-7xl mx-auto px-4 md:px-10'>

        <div className='max-w-7xl py-8 md:py-16 md:grid md:grid-cols-12 md:gap-10'>
          <div className="md:col-span-2 mb-8 md:mb-0">
            <h1 className='text-black text-2xl font-bold leading-tight'>
              Say hello.
            </h1>
          </div>
          <div className='md:col-start-3 md:col-span-4 mb-8 md:mb-0 text-justify'>
            <h2 className='text-black text-2xl leading-tight'>
              Lets bring vending and kiosks into the 21st Century together.
            </h2>
          </div>
          <div className='md:col-start-7 md:col-span-6 mb-8 md:mb-0 text-right'>
            <a href='mailto:hello@vendos.io' className='text-neutral-400 text-2xl leading-tight'>
              Contact us
            </a>
          </div>
        </div>

        <hr className="mt-4 border-t border-neutral-200" />

        <div className='max-w-7xl py-4 md:py-8 md:grid md:grid-cols-12 md:gap-10'>
          <div className="md:col-span-3">
            <h1 className='text-black font-bold leading-tight pb-10'>
              Canada
            </h1>
            <p className='text-neutral-400 leading-relaxed'>
              23 Mississaga St W,
              <br />
              Orillia,
              <br />
              Ontario,
              <br />
              L3V 3A5
            </p>
          </div>
          <div className="md:col-span-3">
            <h1 className='text-black font-bold leading-tight pb-10'>
              Support
            </h1>
            <p className='text-neutral-400 leading-relaxed'>
              <a href='mailto:support@vendos.io'>developer@vendos.io</a>
              <br />
              <a href='mailto:support@vendos.io'>designer@vendos.io</a>
              <br />
              <a href='mailto:support@vendos.io'>hardware@vendos.io</a>
            </p>
          </div>
          <div className="md:col-start-10 md:col-span-3">
            <h1 className='text-black font-bold leading-tight pb-10'>
              Links
            </h1>
            <p className='text-neutral-400 leading-relaxed'>
              <a href="https://developer.vendos.io/" target='_blank' rel="noreferrer">Documentation</a>
            </p>
          </div>

        </div>

        <hr className="border-t border-neutral-200" />
        <div className='max-w-7xl py-6 md:grid md:grid-cols-12 md:gap-10'>
          <div className="md:col-span-7 mb-8 md:mb-0">
            <p className='text-neutral-400 text-sm leading-tight'>
              ClarkesThird Inc. trading as vendOS | Copyright 2024 ClarkesThird Inc.
            </p>
          </div>
          {/* <div className='md:col-start-11 md:col-span-2 mb-8 md:mb-0'>
            <a href='https://trmnlgroup.com' target='_blank' rel='noreferrer'><img src={TerminalGroup} alt='Terminal Logo' className='h-4 w-auto float-right' /></a>
          </div> */}
        </div>

      </div>
    </nav>
  );
};

export default Footer;
