import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/logo.svg';
import icon from '../assets/icon.svg';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isShrunk, setShrunk] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setShrunk(true);
      } else {
        setShrunk(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <nav style={{'backgroundColor': 'rgba(255, 255, 255, 0.6)'}} className={`sticky top-0 z-50  border-neutral-200 backdrop-blur-sm  ${isShrunk ? 'py-1' : 'py-6 border-b'} transition-all duration-300`}>
      <div className={`max-w-7xl flex flex-wrap items-center justify-between mx-auto ${isMenuOpen ? 'pb-0' : ''} px-4 md:px-10`}>
        <NavLink to="/" className="flex items-center">
          <img
            src={logo}
            alt="Logo"
            className={`absolute transition-opacity duration-500 ease-in-out ${isShrunk ? 'opacity-0 h-0' : 'opacity-100 h-8'}`}
          />
          <img
            src={icon}
            alt="Icon"
            className={`transition-opacity duration-500 ease-in-out ${isShrunk ? 'opacity-100 h-6' : 'opacity-0 h-0'}`}
          />
        </NavLink>
        <button
          onClick={() => setIsMenuOpen(!isMenuOpen)}
          className="inline-flex items-center p-2 text-sm text-black md:hidden focus:outline-none"
          aria-controls="navbar-default"
          aria-expanded="false"
        >
          <span className="sr-only">Open main menu</span>
          <svg className="w-10 h-5" fill="none" viewBox="0 0 17 14" xmlns="http://www.w3.org/2000/svg">
            <path stroke="currentColor" strokeWidth="1" d="M1 1h15M1 7h15M1 13h15"/>
          </svg>
        </button>
        <div className={`${isMenuOpen ? 'block' : 'hidden'} w-full md:block md:w-auto`} id="navbar-default">
          <ul className="text-base text-center flex flex-col py-4 md:flex-row md:space-x-8 md:mt-0">
            {/* <li>
              <NavLink
                to="/"
                end
                className={({ isActive }) => `${isActive ? 'text-black' : 'text-neutral-400'} block py-4 px-3 border-b border-b-neutral-200 border-t border-t-neutral-200 md:p-0 md:border-none`}
              >
                Home
              </NavLink>
            </li> */}
            <li>
              <NavLink
                to="/"
                end
                className={({ isActive }) => `${isActive ? 'text-black' : 'text-neutral-400'} block py-4 px-3 border-b border-b-neutral-200 border-t border-t-neutral-200 md:p-0 md:border-none`}
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/about"
                className={({ isActive }) => `${isActive ? 'text-black' : 'text-neutral-400'} block py-4 px-3 border-b border-b-neutral-200 md:p-0 md:border-none`}
              >
                About
              </NavLink>
            </li>
            <li>
              <a href="https://developer.vendos.io/" target='_blank' rel="noreferrer" className="block py-4 px-3 text-neutral-400 border-b border-b-neutral-200 md:p-0 md:border-none">Docs</a>
            </li>
            {/* <li>
              <a href="https://developer.vendos.io/" className="block py-4 px-3 text-neutral-400 md:p-0 md:border-none">Login</a>
            </li> */}
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;

// className="bg-white border-neutral-200 border-b"